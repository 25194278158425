/* You can add global styles to this file, and also import other style files */
@import "primeicons/primeicons.css";

// Define your color palette
$primary-color: #ff7600; // Primary color
$accent-color: #0073e6; // Accent color 
$warn-color: #ff0000; // Warn color
$primary-light: #ffa64d; // Lighten 30%
$primary-lighter: #ffc299; // Lighten 60%
$primary-dark: #cc5e00; // Darken 20%
$primary-darker: #994600; // Darken 40%

//  
:root {
    --primary-color: #{$primary-color};
    --primary-light: #{$primary-light};
    --primary-lighter: #{$primary-lighter};
    --primary-dark: #{$primary-dark};
    --primary-darker: #{$primary-darker};
}

@import url('./assets/custom-style.scss');

html,
body {
    height: 100%;
    margin: 0;
    background-color: #EEEEEE;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.btn-outline-primary {
    border-color: #ff7600 !important;
    color: $primary-color !important;
}

.btn-outline-primary:hover {
    background-color: $primary-color !important;
    border-color: $primary-color !important;
    color: white !important;
}

.bg-grey {
    width: 60px;
    height: 60px;
    background-color: #f3f3f3;
    border-radius: 50%;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    .material-icons{
        color: $primary-color;
        font-size: 30px;
        
    }
}