.form-control:focus {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border-color: var(--primary-light);
    outline: 0;
    box-shadow: 0 0 0 .25rem var(--primary-lighter);
}


.custom-table {
    thead tr th {
        vertical-align: middle;
        padding: 12px 15px;
        font-size: 13px;
        color: var(--text-color);
        font-weight: bolder;
    }

    tbody tr td {
        vertical-align: middle;
        padding: 12px 15px;
        font-size: 13px;
        color: var(--text-color);
        font-weight: normal;
    }

    tbody tr:last-child td {
        border-bottom: 0;
    }
}


.custom-card-title {
    color: #101828;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
}

.custom-badge {
    // background-color: #F9F5FF;
    // color: #6941C6;
    background-color: #fff4e5;
    color: #ff7600;
    height: 22px;
    width: 85px;
    border-radius: 16px;
    padding: 2px 8px;
    font-weight: 500;
}

.custom-desc {
    color: #667085;
    font-size: 14px;
    line-height: 20px;
}


/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #ff7600;
}

input:focus+.slider {
    box-shadow: 0 0 1px #ff7600;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}


.empty-div {

    & {
        height: 374px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .empty-icon {

        & {
            display: flex;
            justify-content: center;
            font-size: 48px;
            color: #ff7600;
        }

        div {
            display: flex;
            width: 50px;
            height: 50px;
            padding: 12px;
            justify-content: center;
            align-items: center;
            border-radius: 28px;
            border: 8px solid #fff4e5;
            background: var(--primary-lighter, #F4EBFF);
        }
    }

    .empty-text {

        & {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-top: 16px;

        }

        p {
            color: var(--gray-900, #101828);
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            margin: 0px;
        }

        h3 {
            color: var(--gray-500, #667085);
            text-align: center;
            font-size: 14px;
            line-height: 20px;
            margin: 0px;
        }

    }

    .empty-btn {

        & {
            display: flex;
            column-gap: 12px;
            justify-content: center;
            width: 100%;
            margin-top: 24px;
        }

        .clr {
            display: flex;
            padding: 10px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            flex: 1 0 0;
            border-radius: 8px;
            border: 1px solid var(--gray-300, #D0D5DD);
            background: var(--white, #FFF);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            color: var(--gray-700, #344054);
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
        }

        .add {
            display: flex;
            padding: 10px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            flex: 1 0 0;
            border-radius: 8px;
            border: 1px solid var(--primary-600, #7F56D9);
            background: var(--primary-600, #7F56D9);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            color: var(--white, #FFF);
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
        }

    }
}